import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";
import ArticleWidget from "../../components/widgets/ArticleWidget";
import ArticleWidget2 from "../../components/widgets/ArticleWidget2";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useEffect, useState} from "react";
import SeoBlock from "../../components/SeoBlock";
import {hiddenScroll} from "../../components/helpers";

function transliterate(text) {
    const map = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'yo', 'ж': 'zh', 'з': 'z', 'и': 'i',
        'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
        'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
        'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch',
        'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '',
        'э': 'e', 'ю': 'yu', 'я': 'ya',
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
        'Е': 'E', 'Ё': 'Yo', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
        'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
        'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
        'У': 'U', 'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch',
        'Ш': 'Sh', 'Щ': 'Shch', 'Ъ': '', 'Ы': 'Y', 'Ь': '',
        'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
    };

    return text.split('').map(char => (map[char] !== undefined ? map[char] : char)).join('');
}

export default function OnlaynTransliterator() {
    const { t } = useTranslation();
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [textareaRows, setTextareaRows] = useState(2);

    useEffect(() => {
        const handleTextareaRows = () => {
            setTextareaRows(window.innerWidth < 768 ? 2 : 5);
        };

        handleTextareaRows();

        window.addEventListener('resize', handleTextareaRows);
        window.addEventListener('orientationchange', handleTextareaRows);

        return () => {
            window.removeEventListener('resize', handleTextareaRows);
            window.removeEventListener('orientationchange', handleTextareaRows);
        }
    }, []); // Will fire only once

    const handleInputChange = (e) => {
        const input = e.target.value;

        setInputText(input);
        setOutputText(transliterate(input));
    };

    const handleTransliterate = () => {
        setOutputText(transliterate(inputText.trim()));
    };



    return (
        <Layout>
            <Seo title={t('onlaynTransliteratorH1')} description={t('onlaynTransliterator')} image="../../services/my-screen-resolution.png" />
            <PageHeaderBlock h1="onlaynTransliteratorH1" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="ourServicesWord" firstChildUrl={'/services'} secondChild="onlaynTransliterator" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <Row className="d-block">
                            <Col className="w-100">
                                <textarea
                                    placeholder={t('enterTransliterateText')}
                                    value={inputText}
                                    rows={textareaRows}
                                    onChange={handleInputChange}
                                    onBlur={handleTransliterate}
                                    className="w-100"
                                />
                            </Col>
                            <Col className="text-center mb-3 mt-2 my-xxl-4 pb-xxl-3">
                                <button onClick={handleTransliterate}
                                        className="btn__big small">{t('transliterateText')}</button>
                            </Col>
                            <Col>
                                <textarea
                                    placeholder={t('transliteratedText')}
                                    value={outputText}
                                    rows={textareaRows}
                                    className="w-100"
                                    readOnly
                                />
                            </Col>
                        </Row>


                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2/></Col>
                </Row>
            </Container>
            <SeoBlock text="onlaynTransliteratorSeoText"/>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;