import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {withPrefix} from "gatsby";

export default function WhyOrder() {
    const { t } = useTranslation();
    const causes = [
        {
            name: "causesToOrder1",
            svg: "shield"
        },
        {
            name: "causesToOrder2",
            svg: "technology"
        },
        {
            name: "causesToOrder3",
            svg: "service"
        },
        {
            name: "causesToOrder4",
            svg: "search"
        }
    ];

    return (
        <Container fluid className="causes px-flex position-relative d-inline-block">
            <div className="my-3 mb-xxl-5 py-2 pb-xl-5">
                <Row>
                    <Col xs={12} className="text-center">
                        <h2 className="h1 lined-head mb-3 mb-xl-4">{t('whyOrder')}</h2>
                        <p className="mb-3 mb-xl-4 pb-xxl-2">{t('causesToOrderText', {causes: causes.length})}</p>
                    </Col>
                    {causes.map((item, index) => {
                        return (
                            <Col md={6} xxl={3} key={`why-order-${index}`} className="my-3 my-md-4 my-xxl-5">
                                <div className="causes__item p-3 p-xl-4 p-xxl-5 d-flex flex-wrap justify-content-between flex-column h-100">
                                    <svg className="svg-icon mx-auto my-3 my-xl-5">
                                        <use xlinkHref={withPrefix(`sprite.svg#${item.svg}-icon`)}></use>
                                    </svg>
                                    {item.name &&
                                        <p className="causes__item-name h4 text-uppercase opacity-50 mb-0 px-xl-2 px-xxl-3 py-2 py-xl-3 text-center">{t(item.name)}</p>
                                    }
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </Container>
    )
}